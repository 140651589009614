.hero {
  height: 90vh;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  display: flex;
  justify-content: flex-end;
}
.hero_container {
  max-width: 100%;
  width: 100%;
  min-height: 100%;
  margin: auto;
  background-color: rgba(28, 25, 25, 0.4);
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
}

.hero_content {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding-bottom: 58px;
}

.hero_content h2 {
  color: var(--color-white);
  font-size: 64px;
  font-weight: 500;
  line-height: 135%;
  text-transform: uppercase;
}

.hero_content p {
  width: 80%;
  color: var(--color-text-white);
  font-size: 16px;
  font-weight: 400;
  line-height: 135%;
}

@media screen and (max-width: 640px) {
  .hero_content p {
    width: 100%;
  }
}

@media screen and (max-width: 425px) {
  .hero_content h2 {
    font-size: 28px;
    margin-bottom: 14px;
  }
  .hero_content {
    padding-top: 190px;
    padding-bottom: 44px;
  }
  .hero {
    height: auto;
  }
}

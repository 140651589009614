.lines_container {
  display: flex;
  flex-direction: column;
  gap: 32px;
  min-height: 74vh;
}

.lines_container h3 {
  font-weight: 500;
  font-size: 48px;
}

.lines_list {
  display: flex;
  flex-wrap: wrap;
  gap: 2%;
}

.lines_card {
  width: 30%;
  min-width: 200px;
}

.lines_card h3 {
  font-size: 32px;
}

.lines_card h3::first-letter {
  text-transform: uppercase;
}

.lines_card img {
  width: 100%;
}

@media screen and (max-width: 734px) {
  .lines_list {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
    margin-bottom: 102px;
  }
  .lines_card {
    width: 100%;
  }
}

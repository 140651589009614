.about_partners {
  margin-bottom: 102px;
}
.about_partners h2 {
  font-size: 48px;
  font-weight: 500;
  color: var(--color-text-black);
  margin-bottom: 42px;
}
.about_content {
  display: flex;
  gap: 20px;
  margin-bottom: 42px;
}

.about_content_left {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 12px;
}
.about_content_left h2 {
  font-size: 24px;
  margin-bottom: 0;
}
.about_images {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  row-gap: 30px;
}

.about_images img {
  width: 49%;
}

@media screen and (max-width: 1154px) {
  .about_content {
    flex-direction: column;
  }
}

@media screen and (max-width: 767px) {
  .about_partners h2 {
    font-size: 24px;
  }
}

@media screen and (max-width: 588px) {
  .about_images img {
    width: 100%;
  }
}

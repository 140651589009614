.mission {
  display: flex;
  justify-content: space-between;
  margin-top: 102px;
  margin-bottom: 102px;
}

.mission h3 {
  font-size: 48px;
  color: var(--color-text-black);
  text-transform: uppercase;
  font-weight: 500;
  line-height: 135%;
}

.mission p {
  max-width: 50%;
  color: var(--color-text-black);
  font-size: 16px;
  font-weight: 400;
  line-height: 135%;
}
@media screen and (max-width: 7575px) {
  .mission {
    gap: 20px;
  }
}

@media screen and (max-width: 640px) {
  .mission {
    flex-direction: column;
    row-gap: 14px;
    margin: 52px 0;
  }
}

@media screen and (max-width: 425px) {
  .mission h3 {
    font-size: 24px;
  }

  .mission p {
    min-width: 100%;
  }
}

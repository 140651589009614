* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.homer_page_animation {
  margin-top: -80px;
  min-height: 100vh;
  overflow: hidden;
  background-color: var(--color-text-black);
  position: relative;
}

.animation_wrapper {
  width: 100vw;
  position: relative;
  right: -50px;
  top: 25px;

  .main_block {
    $size: 200px;
    $margin: 250px;

    width: $size;
    height: $size;
    background-image: url(../../../assets/icons/tunduk.png);

    background-position: center;
    background-size: contain;
    border-radius: 50%;
    position: absolute;
    left: 55%;
    margin-top: 18%;
    margin-left: 10%;

    .blocks {
      $block-size: 100px;
      $offset: 120;
      $angle-step: 360deg / 8;
      cursor: pointer;

      position: absolute;
      background-color: gray;
      transform-origin: -50% 50%;
      border-radius: 20px;
      transition: all 0.3s ease-in-out;
      transform: rotate(90deg);
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;

      @for $i from 1 through 8 {
        &.block#{$i} {
          box-sizing: content-box;
          top: 50%;
          left: 100%;
          width: 50%;
          height: 60px;
          transform: translate(-50%, -50%)
            rotate(#{$angle-step * $i + $offset})
            translateY(-120px);
          animation: rotate-block#{$i} 10s linear infinite;
        }

        @media screen and (min-width: 767px) {
          &.block#{$i}:hover {
            padding: 5% 10%;
            margin-left: 20%;
            margin-top: 7%;
            transform: translate(-50%, -50%)
              rotate(#{$angle-step * $i + $offset}) translateY(-120px);
            background-position: center;

            &.block#{$i} span {
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              display: flex;
              align-items: center;
              justify-content: center;
              color: white;
              font-size: 16px;
              z-index: 3;
              font-weight: 400;
              transition: 0.3s ease-in-out;
              opacity: 1;
              overflow: hidden;
              box-sizing: border-box;
            }
          }
        }

        @media screen and (max-width: 767px) {
          &.block#{$i} {
            user-select: none;
            padding: 5% 10%;
            margin-left: 20%;
            margin-top: 7%;
            transform: translate(-50%, -72%)
              rotate(#{$angle-step * $i + $offset}) translateY(-120px);
            background-position: center;

            &.block#{$i} span {
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              display: flex;
              align-items: center;
              justify-content: center;
              color: white;
              font-size: 16px;
              z-index: 3;
              font-weight: 400;
              transition: 0.3s ease-in-out;
              opacity: 1;
              overflow: hidden;
              box-sizing: border-box;
            }
          }
        }
        @media screen and (max-width: 764px) {
          &.block#{$i} span {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            color: white;
            font-size: 16px;
            z-index: 3;
            font-weight: 400;
            transition: 0.3s ease-in-out;
            opacity: 1;
            overflow: hidden;
            box-sizing: border-box;
          }
        }
        &.block#{$i} span {
          position: absolute;
          transform: rotate(0deg);
          top: 29%;
          left: 13%;
          color: white;
          font-size: 24px;
          text-transform: uppercase;
          font-weight: 500;
          opacity: 0;
        }
      }

      @for $i from 1 through 8 {
        @keyframes rotate-block#{$i} {
          to {
            transform: translate(-50%, -50%)
              rotate(#{$angle-step * $i + $offset + 180deg}) translateY(-120px);
          }
        }
      }
    }

    .block {
      $offset: 90deg;
      $angle-step: 360deg / 16;
      $translateY: -380%;
      width: 60%;
      height: 70px;
      position: absolute;

      transform-origin: 200% 50%;
      border-radius: 20px;
      cursor: pointer;

      @for $i from 9 through 24 {
        &.block#{$i} {
          top: 50%;
          left: 120%;
          transform: translate(-320%, -50%)
            rotate(#{$angle-step * $i + $offset})
            translateY($translateY);
          animation: rotate-block#{$i} 10s linear infinite;
        }
      }

      @for $i from 9 through 24 {
        @keyframes rotate-block#{$i} {
          to {
            transform: translate(-320%, -50%)
              rotate(#{$angle-step * $i + $offset - 180deg})
              translateY($translateY);
          }
        }
      }
    }
  }
}

.diraction_span {
  color: white;
  margin-top: 12px;
  text-transform: none;
}

.block {
  background-image: url(../../../assets/images/photo_2024-02-15_13-15-05.jpg);
  background-size: auto;
  background-repeat: no-repeat;
  background-clip: content-box;
  -webkit-background-clip: content-box;
  -webkit-text-fill-color: transparent;
}

.animation_left {
  width: 40%;
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  top: 100px;
}

.animation_left p {
  color: white;
  font-size: 43px;
}

.animation_left p:first-child {
  text-transform: uppercase;
}

.animation_left h1 {
  background-image: url(../../../assets/images/gold_texture.jpg);
  background-size: cover;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-transform: uppercase;
  font-size: 90px;
  font-weight: 500;
  display: flex;
  flex-direction: column;
  letter-spacing: 5px;
  line-height: 100px;
}

.animation_span {
  font-weight: 300;
}

.animation_left_spans {
  color: white;
  margin-top: 12px;
  text-transform: uppercase;
}

.animation_left_link {
  text-decoration: underline;
}

.home_horse {
  position: absolute;
  bottom: -250px;
  width: 50%;
  left: 20px;
}
.home_horse img {
  width: 100%;
}

@media screen and (max-width: 1440px) {
  .animation_wrapper .main_block {
    left: 55%;
    margin-top: 15%;
    margin-left: 10%;
  }
}

@media screen and (max-width: 1316px) {
  .animation_wrapper .main_block {
    margin-top: 22%;
  }
}

@media screen and (max-width: 1140px) {
  .animation_wrapper .main_block {
    left: 55%;
    margin-top: 25%;
    margin-left: 10%;
  }
}

@media screen and (max-width: 991px) {
  .animation_wrapper .main_block {
    transform: scale(0.5) translate(-25%, 50%);
    left: 20%;
    margin-top: 30%;
    margin-left: 10%;
  }
  .animation_left {
    transform: translate(-50%, -6%);

    width: 100%;
    left: 50%;
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    top: 100px;
  }
  .animation_wrapper .main_block {
    transform: scale(0.3);
    top: 40px;
    left: 150px;
  }
}

@media screen and (max-width: 890px) {
  .animation_wrapper .main_block {
    transform: scale(0.5);
  }
  .animation_left h1 {
    font-size: 50px;
    line-height: 57px;
  }
  .animation_left p {
    font-size: 32px;
  }
}

@media screen and (max-width: 768px) {
  .animation_wrapper .main_block {
    left: 100px;
    top: 40px;
  }
  .blocks {
    $offset: 100;
  }
  .homer_page_animation {
    min-height: 750px;
    max-height: 750px;
  }
  .diraction_span {
    margin-top: 500px;
  }
}

@media screen and (max-width: 764px) {
  .animation_wrapper .main_block {
    margin-top: 33%;
  }
}

@media screen and (max-width: 646px) {
  .animation_wrapper .main_block {
    left: 15%;
    transform: scale(0.4) translate(-45%, 50%);
    top: 50px;
  }
  .diraction_span {
    margin-top: 425px;
  }
}

@media screen and (max-width: 440px) {
  .animation_wrapper .main_block {
    left: 13%;
    margin-top: 50%;
  }
  .animation_left_spans {
    width: 95%;
  }
}

@media screen and (max-width: 425px) {
  .animation_left h1 {
    font-size: 45px;
    line-height: normal;
  }
  .animation_wrapper .main_block {
    transform: scale(0.3) translate(-100%, 50%);
    margin-top: 50%;
  }
  .animation_left_spans {
    top: 100px;
  }
  .diraction_span {
    margin-top: 100%;
  }
}

@media screen and (max-width: 410px) {
  .animation_wrapper .main_block {
    margin-top: 50%;
  }
}

@media screen and (max-width: 400px) {
  .animation_wrapper .main_block {
    margin-top: 50%;
  }
}

@media screen and (max-width: 386px) {
  .animation_wrapper .main_block {
    margin-top: 75%;
  }
}

@media screen and (max-width: 380px) {
  .animation_wrapper .main_block {
    margin-top: 64%;
  }
}

@media screen and (max-width: 356px) {
  .animation_wrapper .main_block {
    margin-top: 65%;
    left: 12%;
  }
}

@media screen and (max-width: 352px) {
  .animation_wrapper .main_block {
    margin-top: 70%;
    left: 12%;
  }
}

@media screen and (max-width: 350px) {
  .animation_wrapper .main_block {
    margin-top: 70%;
    left: 12%;
  }
}

@media screen and (max-width: 340px) {
  .animation_wrapper .main_block {
    left: 10%;
    margin-top: 70%;
  }
  .diraction_span {
    margin-top: 85%;
  }
}

@media screen and (max-width: 425px) {
  .main_block {
    margin-left: 2%; /* Adjust this value as needed */
  }
  .blocks {
    $offset: 80; /* Adjust this value as needed */
  }
}

@media screen and (max-width: 320px) {
  .animation_wrapper .main_block {
    margin-top: 70%;
  }
}

.hero {
  background-image: url(../../../assets/images/kazakh-women-with-dombra-yurt.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position-y: -100px;
  width: 100%;
  height: 90vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.hero_container {
  max-width: 100%;
  width: 100%;
  height: 100%;
  margin: auto;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgba(28, 25, 25, 0.4);
}

.hero_container h1 {
  max-width: 953px;
  color: var(--color-text-white);
  font-size: 64px;
  font-weight: 500;
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 18px;
}

.hero_container h1 span {
  max-width: 953px;
  background: var(--ede, linear-gradient(180deg, #bd621d 0%, #dabd20 100%));
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 52px;
  font-weight: 500;
}

.hero_container p {
  max-width: 953px;

  color: var(--color-text-white);
  font-size: 16px;
  font-weight: 400;
  line-height: 135%;
  margin-bottom: 42px;
}

@media screen and (max-width: 1515px) {
  .hero {
    background-position: center;
  }
}

@media screen and (max-width: 1000px) {
  .hero_container h1,
  .hero_container p {
    width: 80%;
  }
}

@media screen and (max-width: 640px) {
  .hero_container p {
    width: 100%;
  }
}

@media screen and (max-width: 475px) {
  .hero_container {
    gap: 14px;
    justify-content: flex-start;
    padding-top: 58px;
    padding-bottom: 66px;
  }
  .hero_container h1,
  .hero_container h1 span {
    font-size: 28px;
    text-align: start;
    width: 90%;
  }
  .hero_container h1 br {
    display: none;
  }

  .hero_container p {
    width: 90%;
    text-align: start;
  }
  .hero_container button {
    margin-left: 6%;
    align-self: flex-start;
  }
  .hero {
    height: auto;
  }
}

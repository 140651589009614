.contacts {
  margin-top: 88px;
  margin-bottom: 88px;
}
.contacts_header {
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-bottom: 42px;
}
.contacts_header h2 {
  font-size: 48px;
  font-weight: 500;
  text-transform: uppercase;
  color: var(--color-text-black);
}

.contacts_wrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 21px;
}

.contacts_table {
  width: 50%;
}
.contacts_table_row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 60px;
  margin-top: 20px;
  gap: 10px;
  padding-bottom: 20px;
}
.contacts_table_row div {
  max-width: 50%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.contacts_table_row div p {
  align-self: flex-end;
  text-align: end;
}
.table_title {
  color: var(--color-default-text);
}

@media screen and (max-width: 1320px) {
  .contacts_wrapper {
    flex-direction: column;
    width: 100%;
  }
  .contacts_table {
    width: 100%;
    margin-bottom: 20px;
  }
  .contacts_wrapper img {
    display: none;
  }
}

@media screen and (max-width: 767px) {
  .contacts {
    margin-top: 32px;
  }
  .contacts_header h2 {
    font-size: 24px;
  }
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: none;
}
.overlay.active {
  display: block;
}
.burger_container {
  max-width: 430px;
  width: 0;
  min-width: 0;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: fixed;
  left: 0; /* Изменено с right: 0 на left: 0 */
  top: 0;
  background-color: var(--color-text-black);
  transition: width 1s ease-in-out;
  z-index: 1;
}

.burger_container_active {
  width: 100%;
}

.top {
  position: absolute;
  right: 56px; /* Изменено с left: 56px на right: 56px */
  z-index: 2;
  top: 104px;
}

.top_content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
}

.top .top_content li {
  color: var(--color-default-text);
  font-size: 32px;
  font-weight: 500;
  transition: 0.2s ease-in-out;
  cursor: pointer;
}
.top .top_content li:hover {
  color: var(--color-white);
}
.bottom {
  position: absolute;
  z-index: 2;
  bottom: 40px;
  right: 56px; /* Изменено с left: 56px на right: 56px */
  display: flex;
  gap: 18px;
}

.select {
  cursor: pointer;
}

.language_icon {
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
  filter: brightness(70%);
}

.bottom svg {
  cursor: pointer;
}

.purpose {
  background-image: url(../../../assets/images/vintage-grunge-paper-background.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 100%;
  min-height: 1393px;
  height: auto;
  position: relative;
  padding-bottom: 112px;
}

.rectangle {
  position: absolute;
  top: 0;
  right: 0;
}

.purpose hr {
  width: 100%;
  height: 1px;
  background-color: var(--color-text-black);
  border: none;
  margin-bottom: 20px;
}
.purpose_top_wrapper {
  height: 657px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.purpose_top_left {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
}

.purpose_top_left h2 {
  color: var(--color-text-black);
  font-size: 48px;
  font-weight: 500;
  line-height: 135%;
  text-transform: uppercase;
}

.purpose_top_left p {
  max-width: 584px;
  color: var(--color-text-black);
  font-size: 16px;
  font-weight: 400;
  line-height: 135%;
}

.purpose_top_right {
  align-self: flex-end;
}

.purpose_bottom_wrapper {
  height: auto;
  margin-top: 114px;
}

.purpose_bottom_left {
  min-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  /* padding-bottom: 112px; */
}

.purpose_bottom_header {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.purpose_bottom_item h3 {
  max-width: 610px;
  font-size: 48px;
  color: var(--color-text-black);
  font-weight: 500;
  line-height: 135%;
  text-transform: uppercase;
}

.purpose_bottom_item_content {
  max-width: 50%;
  width: 33%;
  font-size: 16px;
  line-height: 135%;
  align-self: flex-end;
}

.purpose_bottom_item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 32px 0;
}

.purpose_bottom_item p {
  font-size: 24px;
  font-weight: 400;
  line-height: 135%;
}

.purpose_bottom_item span {
  max-width: 50%;
  width: 33%;
}

.between_line {
  margin-top: 53px;
}

@media screen and (max-width: 1118px) {
  .purpose_top_left {
    min-width: 50%;
  }
  .purpose_top_right {
    width: 100%;
    align-self: center;
    margin-top: 70px;
  }
  .purpose_top_right img {
    width: 100%;
  }
}

@media screen and (max-width: 1035px) {
  .purpose {
    padding: 42px 0;
  }
  .purpose_top_wrapper {
    flex-direction: column;
    gap: 24px;
    margin-bottom: 102px;
  }
  .purpose_top_right {
    width: 70%;
    margin-top: 0;
  }
  .purpose_bottom_item {
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin: 32px;
    gap: 12px;
  }

  .purpose_bottom_item_content {
    align-self: flex-start;
  }

  .purpose_bottom_item span {
    margin: auto 0;
  }
}

@media screen and (max-width: 768px) {
  .purpose_bottom_item h3,
  .purpose_bottom_item span {
    max-width: 100%;
    width: 100%;
  }
}

@media screen and (max-width: 475px) {
  .rectangle {
    display: none;
  }
  .purpose_top_left h2 {
    font-size: 24px;
  }
  .purpose_top_left p {
    font-size: 16px;
  }
  .purpose_top_right {
    width: 100%;
  }
  .purpose_bottom_item {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
  }
  .purpose_top_wrapper {
    margin-bottom: 20px;
  }
  .between_line {
    display: none;
  }
  .purpose_bottom_item h3 {
    font-size: 24px;
  }
}

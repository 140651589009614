.calendar_page {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 42px;
  margin-top: 88px;
  margin-bottom: 138px;
}

.event_header {
  font-size: 48px;
  color: var(--color-text-black);
  font-weight: 500;
  text-transform: uppercase;
  line-height: 135%;
  align-self: flex-start;
}
.past_event_header {
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}

.header_line {
  height: 1px;
  background-color: var(--color-text-black);
  width: 100%;
}

.past_event_header span {
  cursor: pointer;
  color: var(--color-text-black);
  font-size: 16px;
  line-height: 135%;
  font-weight: 400;
}

@media screen and (max-width: 1270px) {
  .past_event_header {
    width: 85%;
  }
}

@media screen and (max-width: 965px) {
  .calendar_page h2 {
    align-self: flex-start;
  }
}

@media screen and (max-width: 767px) {
  .calendar_page h2 {
    font-size: 24px;
  }
  .past_event_header span {
    display: none;
  }
  .past_event_header {
    width: 100%;
    align-self: flex-start;
  }
}

.team {
  margin-top: 102px;
  margin-bottom: 102px;
}
.team_header {
  display: flex;
  flex-direction: column;
  margin-bottom: 32px;
}

.team_header h4 {
  font-size: 48px;
  text-transform: uppercase;
  font-weight: 500;
  color: var(--color-text-black);
}

.employees_list {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  row-gap: 32px;
  gap: 20px;
}

.employees {
  max-width: 300px;
  min-height: 400px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.employees img {
  width: 100%;
  min-height: 330px;
  max-height: 330px;
  object-fit: cover;
  object-position: top;
}

.employees_pic {
  max-width: 100%;
  width: 100%;
  max-height: 330px;
  overflow: hidden;
  object-fit: cover;
  display: flex;
  justify-content: center;
  align-items: center;
}

.employees_pic img {
  width: 100%;
  object-fit: contain;
}

.employees p {
  color: var(--color-text-black);
  font-size: 24px;
  font-weight: 400;
  line-height: 135%;
}

.employees span {
  color: var(--color-default-text);
  line-height: 135%;
}

@media screen and (max-width: 1105px) {
  .employees_list {
    justify-content: center;
  }
}

@media screen and (max-width: 732px) {
  .employees_list {
    flex-wrap: wrap;
    justify-content: center;
  }
}

@media screen and (max-width: 425px) {
  .team_header h4 {
    font-size: 24px;
  }
  .employees {
    max-width: 100%;
    width: 100%;
  }
  .employees_pic img {
    width: 100%;
  }
}

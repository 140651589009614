.past_details {
  margin-top: 88px;
}
.past_details_header {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.header_content {
  display: flex;
  flex-direction: column;
  gap: 14px;
}

.header_content h1 {
  font-size: 48px;
  font-weight: 500;
  line-height: 135%;
  color: var(--color-text-black);
}

.header_content p,
.share p {
  color: var(--color-default-text);
  font-size: 16px;
  font-weight: 400;
  text-transform: uppercase;
}

.header_content p {
  display: flex;
  gap: 4px;
}
.header_content p span {
  display: flex;
  gap: 10px;
}
.share {
  display: flex;
  align-items: center;
  gap: 14px;
}
.header_line {
  margin: 42px 0;
}
.about_details {
  display: flex;
  justify-content: space-between;
  margin-bottom: 62px;
  gap: 20px;
}
.about_details img {
  display: flex;
  flex-grow: 1;
  object-position: center;
  object-fit: cover;
  width: 50%;
}
.about_detail_left {
  width: 50%;
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.about_detail_left h3 {
  font-size: 24px;
  font-weight: 400;
  line-height: 135%;
}
.about_details_table {
  margin-top: 42px;
}
.table_row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 80px;
}

.table_row h4 {
  font-size: 24px;
  font-weight: 400;
  line-height: 135%;
  color: var(--color-default-text);
}

.table_row p {
  font-size: 24px;
  font-weight: 400;
  line-height: 135%;
  color: var(--color-text-black);
}

.past_images {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-bottom: 102px;
}
.past_images img {
  width: 49%;
}
.past_images img:first-child {
  width: 100%;
}

.other_events {
  display: flex;
  flex-direction: column;
  gap: 42px;
}

.other_events h3 {
  font-size: 48px;
  font-weight: 500;
  line-height: 135%;
  text-transform: uppercase;
}

@media screen and (max-width: 1175px) {
  .past_images img {
    width: 100%;
  }
  .about_details {
    flex-direction: column;
    width: 100%;
    margin-bottom: 20px;
  }
  .about_detail_left {
    width: 100%;
  }
  .about_details_table {
    width: 100%;
  }

  .about_details img {
    width: 100%;
  }
}

@media screen and (max-width: 767px) {
  .past_details_header h1 {
    font-size: 20px;
  }
  .past_details_header p {
    font-size: 14px;
  }
  .other_events h3 {
    font-size: 24px;
  }
  .share {
    margin-bottom: -7px;
  }
}

@media screen and (max-width: 423px) {
  .past_details_header {
    flex-direction: column;
    align-items: flex-start;
    gap: 14px;
  }
}

.inquiries {
  background-image: url(../../../assets/images/vintage-grunge-paper-background.png);
  background-repeat: no-repeat;
  background-size: cover;

  min-height: 592px;
}

.inquiries_general_block {
  /* max-width: 1121px; */
  margin: auto;
  display: flex;
  justify-content: space-between;
  min-height: 592px;
}

.inquiries_left {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-top: 72px;
  position: relative;
  width: 33%;
  height: 100%;
  min-height: 592px;
}

.partners {
  background-image: url(../../../assets/images/vintage-grunge-paper-background.png);
  background-repeat: no-repeat;
  background-size: cover;
  padding-bottom: 48px;
  margin-top: 102px;
}

.inquires_marquee {
  height: auto;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;
}

.inquires_marquee a {
  width: 200px;
  aspect-ratio: 3/2;
  object-fit: cover;
  display: flex;
  justify-content: center;
  align-items: center;
}

.inquires_marquee img {
  width: 100%;
}

.inquiries_left h6 {
  color: var(--color-text-black);
  font-size: 48px;
  font-weight: 500;
  line-height: 135%;
  text-transform: uppercase;
  max-width: 241px;
}

.inquiries_left span {
  max-width: 290px;
  color: var(--color-text-black);
  font-size: 16px;
  font-weight: 400;
  line-height: 140%; /* 22.4px */
}

.inquiries_left img {
  position: absolute;
  right: 0;
  bottom: 0;
}
.inquiries_right {
  margin-top: 72px;
}

.inquiries_right form {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 18px;
}

.inquiries_right form input {
  width: 100%;
  height: 68px;
  padding: 0px 28px 0px 28px;
  background-color: var(--color-white);
  color: var(--color-text-black);
  border: none;
  outline: none;
  font-size: 20px;
  font-weight: 400;
}

.inquiries_right form input:focus {
  outline: none;
}

.inquiries_right form input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 1000px white inset;
}

.inquiries_right form input::placeholder {
  content: "";
  text-transform: uppercase;
  font-size: 20px;
  color: var(--color-text-black);
  font-weight: 400;
  display: flex;
}

.inquiries_right form button {
  width: 100%;
  height: 68px;
  padding: 0px 28px 0px 28px;
  background-color: var(--color-white);
  color: var(--color-text-black);
  border: none;
  outline: none;
  font-size: 24px;
  font-weight: 400;
  display: flex;
  justify-content: center;
  align-items: center;
}

.check {
  display: flex;
  flex-direction: row-reverse;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 450px;
  position: relative;
}

.check label {
  font-size: 16px;
  font-weight: 400;
  line-height: 135%;
  margin-left: 36px;
  color: var(--color-text-black);
  cursor: pointer;
}

.inquiries_right form textarea[name="description"] {
  /* Добавляем свойство font-family и устанавливаем его равным свойству font-family для input */
  font-family: inherit;
  resize: none;
  max-height: 154px;
  height: 154px;
  width: 100%;
  padding: 25px 28px 0px 28px;
  background-color: var(--color-white);
  color: var(--color-text-black);
  border: none;
  outline: none;
  font-size: 20px;
  font-weight: 400;
}

.inquiries_right form textarea::placeholder {
  content: "";
  text-transform: uppercase;
  font-size: 20px;
  font-weight: 400;
  display: flex;
  color: var(--color-text-black);
}

.check input[type="checkbox"] {
  position: absolute;
  left: 0;
  z-index: 2;
  opacity: 0;
  background-color: var(--color-text-black);
  border: none;
  outline: none;
  min-width: 20px;
  width: 20px;
  min-height: 20px;
  height: 100%;
}

.checkmark {
  position: absolute;
  left: 0;
  min-width: 20px;
  min-height: 20px;
  background-color: transparent;
  border: 2px solid var(--color-text-black);
  border-radius: 5px;
  cursor: pointer;
}

.check input[type="checkbox"]:checked + .checkmark {
  background-image: url(../../../assets/icons/checkboxBlack.svg);
  border: none;
  cursor: pointer;
}

.check input[type="checkbox"]:checked + .checkmarkBg {
  background-image: url(../../../assets/icons/checkbox.svg);

  border: none;

  cursor: pointer;
}

.checkmarkBg {
  position: absolute;
  left: 0;
  min-width: 20px;
  min-height: 20px;
  background-color: transparent;
  border: 2px solid var(--color-white);
  border-radius: 5px;
  cursor: pointer;
}

.checkmark {
  width: 20px;
  height: 20px;
  border: 1px solid black;
  border-radius: 5px;
}

.inquiries_bg_mode {
  background-color: var(--color-text-black);
  border: none;
  height: auto;
  padding: 72px 0;
  margin-top: 102px;
}

.inquiries_bg_mode .inquiries_right {
  width: 50%;
}

.inquiries_bg_mode .inquiries_right form input {
  background-color: var(--input-color);
  color: white;
}

.inquiries_bg_mode .inquiries_right form input::placeholder {
  color: white;
}

.inquiries_bg_mode .inquiries_general_block,
.inquiries_bg_mode .inquiries_left {
  min-height: 0;
  height: auto;
}

.inquiries_bg_mode .inquiries_right,
.inquiries_bg_mode .inquiries_left {
  margin-top: 0;
}

.inquiries_bg_mode .inquiries_right form textarea[name="description"] {
  background-color: var(--input-color);
  color: white;
}

.inquiries_bg_mode
  .inquiries_right
  form
  textarea[name="description"]::placeholder {
  color: white;
}

.inquiries_bg_mode h6 {
  color: var(--color-white);
}
.inquiries_bg_mode span,
.inquiries_bg_mode ul li {
  color: var(--color-alpha);
}

.inquiries_bg_mode ul {
  margin-top: 126px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  font-family: "Manrope", sans-serif;
}

.inquiries_bg_mode .check,
.inquiries_bg_mode .check label {
  color: white;
  cursor: pointer;
}

.header_container {
  padding-top: 88px;
  margin-bottom: 42px;
  display: flex;
  justify-content: space-between;
}
.header_container h2 {
  font-size: 48px;
  font-weight: 500;
  text-transform: uppercase;
  line-height: 135%;
  color: var(--color-text-black);
}

.button_send:disabled {
  cursor: default;
  background-color: #ffffffbb;
  color: #1c1919b7;
}

@media screen and (max-width: 1275px) {
  .inquiries_general_block {
    width: 90%;
  }
}

@media screen and (max-width: 1035px) {
  .inquiries_general_block {
    flex-direction: column;
    justify-content: center;
    padding-bottom: 37px;
  }
  .inquiries_left {
    height: auto;
    min-height: 100%;
    width: 100%;
  }
  .inquiries_left img {
    display: none;
  }

  .inquiries_left h6,
  .inquiries_left span {
    width: 100%;
    max-width: none;
  }

  .inquiries_bg_mode ul {
    display: none;
  }
  .inquiries_bg_mode span {
    margin-bottom: 25px;
  }
  .inquiries_bg_mode .inquiries_right {
    width: 100%;
  }
  .inquiries_bg_mode .check {
    display: flex;
    max-width: 100%;
  }
  .inquiries_bg_mode .check label {
    color: white;
    width: 100%;
  }
}

@media screen and (max-width: 767px) {
  .header_container h2 {
    font-size: 24px;
  }
  .header_container {
    margin-top: 24px;
  }
}

@media screen and (max-width: 475px) {
  .inquiries_left h6 {
    font-size: 24px;
    margin-bottom: 12px;
  }
  .inquiries_right form input::placeholder {
    font-size: 16px;
  }
  .inquiries_right form button {
    font-size: 18px;
  }
}

.about_images {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  row-gap: 30px;
}

.about_images img {
  width: 49%;
}

.infoLines_header {
  margin-top: 102px;
  margin-bottom: 62px;
  display: flex;
  justify-content: space-between;
}

.infoLines_header h2 {
  color: var(--color-text-black);

  font-family: "Helvetica Neue";
  font-size: 48px;
  font-style: normal;
  font-weight: 500;
  line-height: 135%; /* 64.8px */
  text-transform: uppercase;
}

.infoLines_header p {
  width: 49%;
  color: var(--color-text-black);

  font-family: "Helvetica Neue";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 135%;
}

@media screen and (max-width: 588px) {
  .about_images img {
    width: 100%;
  }
}

@media screen and (max-width: 820px) {
  .infoLines_header {
    flex-direction: column;
  }
  .infoLines_header p {
    width: 100%;
  }
}

@media screen and (max-width: 425px) {
  .infoLines_header {
    margin-top: 52px;
    margin-bottom: 32px;
  }
  .infoLines_header h2 {
    font-size: 24px;
    margin-bottom: 14px;
  }
}

.personality_wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  row-gap: 72px;
  margin-bottom: 82px;
}

.personality_card {
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.personality_card h2 {
  font-size: 24px;
  font-weight: 500;
}

.personality_card span {
  color: var(--color-default-text);
  font-size: 16px;
}

@media screen and (max-width: 1090px) {
  .personality_wrapper {
    justify-content: center;
  }
  .personality_card {
    width: 48%;
  }
}

@media screen and (max-width: 588px) {
  .personality_card {
    width: 100%;
  }
}

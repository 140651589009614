.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  width: 100%;
  position: sticky;
  top: 0;
  left: 0;
  z-index: 5;
  background-color: white;
}

.circle {
  min-width: 30px;
  min-height: 30px;
  border-radius: 100%;
  position: relative;
  top: -100px;
  border: 4px solid var(--color-text-black);
  animation: loading 0.8s linear infinite;
}

.circle::before {
  content: "";
  width: 20px;
  height: 20px;
  position: absolute;
  right: -10px;
  bottom: -10px;
  background-color: white;
}

@keyframes loading {
  to {
    transform: rotate(360deg);
  }
}

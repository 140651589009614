.menu-icon-wrapper {
  /* background: red; */
  width: 40px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: relative;
  z-index: 101;
  align-self: flex-end;
}

.menu-icon {
  position: relative;
  z-index: 101;
  width: 100%;
  height: 4px;
  background: linear-gradient(180deg, #bd621d 0%, #dabd20 100%);
  background-clip: content-box;
  -webkit-background-clip: content-box;
}

.menu-icon::before {
  position: absolute;
  z-index: 101;
  left: 0;
  top: -6px;
  content: "";
  width: 100%;
  height: 4px;
  background: linear-gradient(180deg, #bd621d 0%, #dabd20 100%);
  background-clip: content-box;
  -webkit-background-clip: content-box;
  transition: transform 0.2s ease-in, top 0.2s linear 0.2s;
}

.menu-icon::after {
  position: absolute;
  z-index: 2;

  left: 0;
  top: 6px;
  content: "";
  width: 100%;
  height: 4px;
  background: linear-gradient(180deg, #bd621d 0%, #dabd20 100%);
  background-clip: content-box;
  -webkit-background-clip: content-box;
  transition: transform 0.2s ease-in, top 0.2s linear 0.2s;
}

.menu-icon.menu-icon-active {
  background-color: transparent;
}

.menu-icon.menu-icon-active::before {
  transform: rotate(45deg);
  background-color: white;
  top: 0;
  cursor: pointer;
  transition: top 0.2s linear, transform 0.2s ease-in 0.2s;
}

.menu-icon.menu-icon-active::after {
  transform: rotate(-45deg);
  background-color: white;
  cursor: pointer;
  top: 0;
  transition: top 0.2s linear, transform 0.2s ease-in 0.2s;
}

.past_event {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 20px;
  row-gap: 32px;
}
.past_event_card {
  width: 32%;
  display: flex;
  flex-direction: column;
  gap: 14px;
}

.past_event_card span {
  color: var(--color-default-text);
  text-transform: uppercase;
  font-size: 16px;
  cursor: pointer;
  gap: 4px;
}

.past_event_card h2 {
  color: var(--color-text-black);
  font-weight: 500;
  font-size: 24px;
  line-height: 135%;
  cursor: pointer;
}
.past_event_card img {
  max-width: 400px;
  max-height: 300px;
  height: 300px;
  object-fit: cover;
  object-position: center;
  width: 100%;
  cursor: pointer;
}

.past_event_card h2:hover,
.past_event_card span:hover {
  text-decoration: underline;
}

@media screen and (max-width: 965px) {
  .past_event {
    justify-content: center;
  }
  .past_event_card {
    width: 100%;
  }
}

@media screen and (max-width: 767px) {
  .past_event_card span {
    font-size: 16px;
  }
  .past_event_card h2 {
    font-size: 20px;
  }
  .past_event_card img {
    width: 100%;
  }
}

.footer {
  background-color: var(--color-text-black);
  height: auto;
  padding: 38px 0;
  display: flex;
  align-items: center;
}

.footer_general_wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.footer_left_main_wrapper {
  display: flex;
  justify-content: space-between;
  width: 50%;
}

.footer_left {
  display: flex;
  flex-direction: column;
  gap: 8px;
  cursor: pointer;
}

.footer_left span,
.footer_mobile_span {
  color: var(--color-default-text);
  font-size: 14px;
  line-height: 135%;
  font-family: "Manrope", sans-serif;
  cursor: pointer;
}
.footer_left span,
.footer_mobile_span span:hover {
  text-decoration: underline;
}
.footer_mobile_span {
  display: none;
}

.footer_center {
  display: flex;
  justify-content: space-between;
  gap: 48px;
}

.footer_center ul {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.footer_center ul li {
  font-size: 14px;
  color: var(--color-default-text);
  line-height: 135%;
}

.footer_right {
  display: flex;
  gap: 18px;
}

@media screen and (max-width: 1100px) {
  .footer_left_main_wrapper {
    gap: 28px;
    width: 60%;
  }
}

@media screen and (max-width: 965px) {
  .footer_left {
    width: 40%;
  }
  .footer_center {
    align-items: center;
  }
}

@media screen and (max-width: 640px) {
  .footer_general_wrapper {
    flex-direction: column;
  }
  .footer_left_main_wrapper {
    flex-direction: column;
  }
  .footer_general_wrapper {
    align-items: flex-start;
    row-gap: 30px;
  }
  .footer_left span {
    display: none;
  }
  .footer_mobile_span {
    display: inline;
  }
}

.blog_details {
  display: flex;
  flex-direction: column;
  margin-top: 88px;
}
.blog_details_header {
  display: flex;
  width: 100%;
  margin-bottom: 42px;
  justify-content: space-between;
}
.blog_details hr {
  margin-bottom: 42px;
}
.blog_details_content {
  display: flex;
  flex-direction: column;
  gap: 14px;
}
.blog_details_content h2 {
  font-size: 48px;
  font-weight: 500;
}
.blog_details_content .date {
  color: var(--color-default-text);
  font-style: 16px;
  text-transform: uppercase;
}
.share {
  display: flex;
  align-items: center;
  align-self: flex-end;
  gap: 16px;
}

.share p {
  color: var(--color-default-text);
  text-transform: uppercase;
}

.share svg {
  cursor: pointer;
}

.blog_details_description {
  max-width: 820px;
  width: 100%;
  margin: 42px auto;
  line-height: 150%;
  color: var(--color-text-black);
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.blog_details_description p {
  font-weight: 400;
}

.blog_details_description p strong {
  font-weight: 800 !important;
}

.next_page_wrapper {
  display: flex;
  justify-content: flex-start;
  gap: 20px;
  margin-top: 32px;
  margin-bottom: 99px;
}

.next_page h2 {
  font-weight: 500;
  font-size: 48px;
  line-height: 135%;
  color: var(--color-text-black);
}

.next_page_card {
  width: 32%;
  display: flex;
  flex-direction: column;
  object-fit: cover;
  gap: 14px;
}

.next_page_card img {
  max-width: 100%;
  object-fit: cover;
  min-height: 300px;
  max-height: 300px;
}

.next_page_card p {
  color: var(--color-default-text);
  text-transform: uppercase;
  font-size: 16px;
  cursor: pointer;
}
.source {
  font-size: 13px;
}
.source a {
  text-decoration: underline;
  color: var(--color-text-black);
  font-style: italic;
}

.next_page_card h2 {
  font-size: 24px;
  color: var(--color-text-black);
  font-weight: 500;
  line-height: 135%;
  cursor: pointer;
}

.next_page_card p:hover,
.next_page_card h2:hover {
  text-decoration: underline;
}

.images {
  margin-bottom: 32px;
}

.main_details_img {
  display: flex;
  gap: 16px;
}
.main_details_img img {
  width: 50%;
}
@media screen and (max-width: 1300px) {
  .next_page_wrapper {
    flex-wrap: wrap;
    width: 100%;
    gap: 20px;
  }
  .next_page_card {
    display: flex;
    width: 32%;
  }
}

@media screen and (max-width: 866px) {
  .next_page_wrapper {
    width: 100%;
    row-gap: 32px;
  }
}

@media screen and (max-width: 767px) {
  .next_page_wrapper {
    flex-direction: column;
    width: 100%;
  }
  .next_page_card {
    width: 100%;
  }
  .blog_details_content h2 {
    font-size: 20px;
  }
  .blog_details_content .date {
    font-size: 14px;
  }
  .share p {
    font-size: 14px;
  }
  .next_page h2 {
    font-size: 24px;
  }
  .next_page_card p {
    font-size: 14px;
  }
  .next_page_card h2 {
    font-size: 20px;
  }
  .next_page_card img {
    max-width: 100%;
  }
  .main_details_img {
    flex-direction: column;
    justify-content: center;
  }
  .main_details_img img {
    width: 100%;
  }
}
@media screen and (max-width: 423px) {
  .blog_details_header {
    flex-direction: column;
    align-items: flex-start;
    gap: 14px;
  }
  .share {
    align-self: flex-start;
  }
}

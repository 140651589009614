.services {
  margin-top: 102px;
}

.services_bg {
  background-image: url(../../../assets/images/vintage-grunge-paper-background.png);
  background-repeat: no-repeat;
  background-size: cover;
  padding: 115px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
}

.services_bg .rectangle {
  position: absolute;
  top: 0;
  right: 0;
}

.services_bg .number {
  color: var(--color-text-black);
}

.services_bg .accordion_details {
  padding-bottom: 32px;
}

.services_bg .accordion_details ul li {
  list-style-type: disc;
  margin-left: 18px;
}

.services_line {
  height: 0;
  border: 1px solid var(--color-text-black);
}

.services h4,
.services_bg h4 {
  color: var(--color-text-black);
  font-size: 48px;
  font-weight: 500;
  line-height: 135%;
  text-transform: uppercase;
  margin-bottom: 32px;
}

.accordion {
  display: flex;
  flex-direction: column;
}

.accordion_item {
  width: 100%;
  position: relative;
  overflow: hidden;
  transition: max-height 0.6s ease;
}

.accordion_content,
.accordion_content_none {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  margin-bottom: 12px;
}

.number {
  color: var(--color-default-text);
  font-size: 24px;
}

.years {
  color: var(--color-text-black);
}

.info {
  width: 55%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 96px;
}

.info p {
  font-size: 24px;
  font-weight: 400;
}

.info svg {
  cursor: pointer;
}

.accordion_item.accordion_active {
  max-height: 2000px; /* Set a large max-height for open state */
}

.accordion_item .accordion_details {
  display: flex;
  flex-direction: column;
  max-width: 55%;
  margin-right: 0;
  margin-left: auto;
  margin-top: -10px;
}

.accordion_item.accordion_active .accordion_details {
  display: flex;
  flex-direction: column;
  max-width: 55%;
  margin-right: 0;
  margin-left: auto;
  margin-top: -10px;
}

.accordion_details .accordion_button {
  margin-bottom: 30px;
  width: 35%;
  padding-right: 26px;
}

.accordion_details img {
  margin-bottom: 20px;
  max-height: 317px;
  height: 100%;
  object-fit: cover;
}

.page_none {
  display: none;
}

.page_text {
  color: var(--color-text-black);
  max-width: 630px;
  width: 100%;
  line-height: 135%;
  margin-bottom: 42px;
}

@media screen and (max-width: 1248px) {
  .accordion_details .accordion_button {
    width: 40%;
  }
}

@media screen and (max-width: 1110px) {
  .accordion_details .accordion_button {
    width: 50%;
  }
}

@media screen and (max-width: 920px) {
  .accordion_details .accordion_button {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-right: 15%;
  }
}
@media screen and (max-width: 768px) {
  .services {
    margin-top: 32px;
  }
  .accordion_item.accordion_active .accordion_details {
    max-width: 100%;
    min-width: 100%;
    margin: auto;
  }
  .accordion_item .accordion_details {
    max-width: 100%;
    margin-top: -10px;
    margin: auto;
  }

  .accordion_details .accordion_button {
    padding-right: 7%;
    padding-left: 3%;
    width: 30%;
    align-self: flex-start;
  }
  .info {
    width: 100%;
  }
  .accordion_content,
  .accordion_content_none {
    gap: 6px;
  }
}

@media screen and (max-width: 640px) {
  .years {
    border: 1px solid black;
    padding: 1px 10px;
    color: var(--color-text-black);
  }

  .accordion_details {
    margin-top: 20px;
  }

  .accordion_item {
    min-height: 88px;
    margin: 4px 0;
  }
  .accordion_content {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 134px;
    gap: 9px;
  }
  .accordion_content_none {
    align-items: center;
    justify-content: center;
    min-height: 0;
    height: auto;
    gap: 9px;
  }
  .accordion_content_none .info {
    padding: 0;
    align-self: center;
  }
  .accordion_content_none .info svg,
  .accordion_content_none .info p {
    align-self: center;
  }
  .info {
    width: 100%;
    padding: 25px 0;
  }
  .info svg {
    align-self: flex-start;
  }
  .accordion_details .accordion_button {
    width: 50%;
  }
}

@media screen and (max-width: 475px) {
  .services h4,
  .services_bg h4 {
    font-size: 24px;
  }
  .info p,
  .number,
  .years {
    font-size: 20px;
  }
  .info p {
    width: 90%;
  }
  .accordion_item {
    margin: 0;
  }
}

@media screen and (max-width: 372px) {
  .accordion_details .accordion_button {
    width: 60%;
  }
}

/* fonts */
@import url("https://fonts.cdnfonts.com/css/helvetica-neue-5");
@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;600;700;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;600;700;800&family=Nunito+Sans:opsz,wght@6..12,300;6..12,400;6..12,500;6..12,600;6..12,700;6..12,800;6..12,900;6..12,1000&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

input,
textarea {
  cursor: text;
}

:root {
  --color-white: #fff;
  --color-text-black: #1c1919;
  --color-default-text: #939393;
  --color-text-white: #fafafa;
  --color-alpha: rgba(255, 255, 255, 0.8);
  --input-color: #272727;
}

body[lang="en"] {
  font-family: "Helvetica Neue";
}
body[lang="ru"] {
  font-family: "Manrope", sans-serif;
  font-family: "Nunito Sans", sans-serif;
}

button {
  cursor: pointer;
}

li {
  list-style-type: none;
  cursor: pointer;
}

.container {
  max-width: 1240px;
  width: 100%;
  min-width: 300px;
  height: auto;
  margin: 0 auto;
}

@media screen and (max-width: 1275px) {
  .container {
    width: 85%;
  }
}

@media screen and (max-width: 475px) {
  .container {
    width: 90%;
  }
}

.blog_list_wrapper {
  margin-bottom: 272px;
  margin-top: 32px;
}
.blog_header {
  min-height: 88px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 42px;
}
.blog_header h2 {
  font-size: 48px;
  color: var(--color-text-black);
  font-weight: 500;
  text-transform: uppercase;
}
.blog_list {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 20px;
  row-gap: 72px;
}

.blog_card {
  max-width: 32%;
  display: flex;
  flex-direction: column;
  gap: 14px;
}

.blog_card img {
  width: 100%;
  object-fit: cover;
  cursor: pointer;

  min-height: 300px;
  max-height: 300px;
}

.blog_card p {
  color: var(--color-default-text);
  font-size: 16px;
  text-transform: uppercase;
  cursor: pointer;
}

.blog_card h2 {
  color: var(--color-text-black);
  font-size: 24px;
  font-weight: 400;
  line-height: 135%;
  cursor: pointer;
}

.blog_card h2:hover,
.blog_card p:hover {
  text-decoration: underline;
}

.blog_list > .blog_card:nth-child(4),
.blog_list > .blog_card:nth-child(5),
.blog_list > .blog_card:nth-last-child(2),
.blog_list > .blog_card:last-child {
  width: 49%;
}

@media screen and (max-width: 1176px) {
  .blog_card {
    max-width: 30%;
  }
  .blog_list > .blog_card:nth-child(4),
  .blog_list > .blog_card:nth-child(5),
  .blog_list > .blog_card:nth-last-child(2),
  .blog_list > .blog_card:last-child {
    width: 47%;
  }
}

@media screen and (max-width: 767px) {
  .blog_list > .blog_card:nth-child(4),
  .blog_list > .blog_card:nth-child(5),
  .blog_list > .blog_card:nth-last-child(2),
  .blog_list > .blog_card:last-child,
  .blog_card {
    max-width: 100%;
    width: 100%;
  }
  .blog_list {
    max-width: 100%;
    justify-content: center;
    width: 100%;
    gap: 0;
    row-gap: 72px;
  }
  .blog_header h2 {
    font-size: 24px;
  }
  .blog_card p {
    font-size: 14px;
  }
  .blog_card h2 {
    font-size: 20px;
  }
  .blog_list_wrapper {
    margin-bottom: 72px;
  }
}

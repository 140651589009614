.header_container {
  margin-top: 88px;
  margin-bottom: 42px;
}
.header_container h2 {
  font-size: 48px;
  font-weight: 500;
  text-transform: uppercase;
  line-height: 135%;
  color: var(--color-text-black);
}
.header_partners {
  background-image: url(../../../assets/images/partners_header_bg.png);
}

.header_partner_wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 60px;
  padding: 80px 0;
}

.header_top,
.header_bottom {
  display: flex;
  align-items: center;
}

.header_top {
  width: 100%;
  justify-content: space-between;
  flex-wrap: wrap;
}

.header_bottom {
  width: 100%;
  justify-content: space-evenly;
}

@media screen and (max-width: 1272px) {
  .header_top,
  .header_bottom {
    gap: 31px;
  }
}

@media screen and (max-width: 767px) {
  .header_partners {
    background-repeat: no-repeat;
    background-size: cover;
  }
  .header_top,
  .header_bottom {
    width: 100%;
    justify-content: center;
    flex-wrap: wrap;
  }
  .header_mobile_none {
    display: none;
  }
  .header_container h2 {
    font-size: 24px;
  }
  .header_container {
    margin-top: 24px;
  }
}

@media screen and (max-width: 590px) {
  .header_top {
    width: 100%;
    justify-content: space-between;
  }
  .header_top svg {
    width: 25%;
  }
  .header_bottom {
    width: 100%;
    justify-content: space-between;
  }
  .header_bottom svg {
    width: 25%;
  }
}

.news {
  margin-top: 102px;
}
.news_header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.news_header h5 {
  color: var(--color-text-black);
  font-size: 48px;
  font-weight: 500;
  line-height: 135%;
  text-transform: uppercase;
}

.news_header span {
  font-size: 16px;
  font-weight: 400;
  color: var(--color-text-black);
  cursor: pointer;
}

.news_list {
  margin-top: 32px;
  display: flex;
  justify-content: flex-start;
  gap: 3%;
  margin-bottom: 62px;
}

.news_card {
  max-width: 400px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 18px;
}

.news_card img {
  max-height: 287px;
  min-height: 287px;
  height: 100%;
  width: 100%;
  object-fit: cover;
  cursor: pointer;
}

.news_card .news_date {
  color: var(--color-default-text);
  font-size: 16px;
  font-weight: 400;
  text-transform: uppercase;
  cursor: pointer;
}

.news_card .news_title {
  color: var(--color-text-black);
  font-size: 24px;
  font-weight: 500;
  line-height: 135%;
  cursor: pointer;
}

.news_card .news_title:hover,
.news_card .news_date:hover {
  text-decoration: underline;
}

.news_redirect {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-bottom: 72px;
}

.new_button {
  margin: 0 auto;
}

@media screen and (max-width: 1300px) {
  .news_list {
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
    row-gap: 32px;
  }
}

@media screen and (max-width: 768px) {
  .news_header span {
    display: none;
  }
}

@media screen and (max-width: 475px) {
  .news_header h5 {
    font-size: 24px;
  }
  .news_card {
    max-width: 100%;
  }
  .news_date {
    font-size: 16px;
  }
  .news_card .news_title {
    font-size: 20px;
  }
}

.calendar {
  margin-bottom: 102px;
  width: 100%;
}
.card {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: auto;
  padding: 42px 0;
}

.data {
  display: flex;
  justify-content: space-between;
}

.date span {
  background: linear-gradient(180deg, #bd621d 0%, #dabd20 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 48px;
  font-weight: 500;
  line-height: 135%;
}
.date p {
  color: var(--color-text-black);
  font-weight: 400;
  text-transform: uppercase;
}

.card img {
  max-width: 505px;
  margin-left: 20px;
}
.content {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 42px;
}
.content h2 {
  font-size: 32px;
  font-weight: 500;
  line-height: 135%;
}

@media screen and (max-width: 965px) {
  .card,
  .data {
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
  }
  .card,
  .card img {
    width: 100%;
    max-width: 100%;
    margin: 0 auto;
  }
  .content button {
    display: none;
  }
  .card {
    position: relative;
    gap: 42px;
  }
  .date {
    position: absolute;
    background-color: white;

    padding: 6px 20px 7px 12px;
    bottom: 157px;
    left: 12px;
  }

  .content {
    width: 100%;
  }
}

@media screen and (max-width: 767px) {
  .date {
    bottom: 107px;
    left: 12px;
  }
  .date span {
    font-size: 24px;
  }
  .date p {
    font-size: 14px;
  }
  .content h2 {
    font-size: 20px;
  }
}

@media screen and (max-width: 750px) {
  .date {
    bottom: 147px;
  }
}

@media screen and (max-width: 412px) {
  .date {
    bottom: 170px;
  }
}

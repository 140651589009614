.not_found {
  min-height: 90vh;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.not_found_container {
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.not_found_container svg {
  margin-bottom: 32px;
}

.not_found_container h2 {
  margin-bottom: 12px;
  text-transform: uppercase;
  font-size: 32px;
  font-weight: 500;
  line-height: 135%;
  color: var(--color-text-black);
  text-align: center;
}

.not_found_container p {
  margin-bottom: 42px;
}

@media screen and (max-width: 475px) {
  .not_found_container h2 {
    font-size: 20px;
  }
  .not_found_container p {
    font-size: 16px;
  }
}

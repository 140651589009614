.header {
  max-width: 100%;
  width: 100%;
  height: 80px;
  display: flex;
  align-items: center;
  position: sticky;
  top: 0;
  left: 0;
  background-color: var(--color-white);
  z-index: 100;
}

.header_default {
  max-width: 100%;
  width: 100%;
  height: 80px;
  display: flex;
  align-items: center;
  position: sticky;
  top: 0;
  left: 0;
  background-color: var(--color-white);
  z-index: 99;
}

.header_none {
  max-width: 100%;
  width: 100%;
  height: 80px;
  display: flex;
  align-items: center;
  position: sticky;
  top: 0;
  left: 0;
  background-color: transparent;
  z-index: 100;
}

.nav {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.nav_left {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
}

.nav_left button {
  display: none;
}

.left_block__burger {
  align-self: flex-start;
}

.nav button,
.nav_left button {
  background: transparent;
  border: none;
}

.logo {
  cursor: pointer;
}

.left_block {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.nav .left_block_content {
  display: flex;
  align-items: center;
  gap: 48px;
}

.nav .left_block_content li {
  cursor: pointer;
  font-weight: 400;
  line-height: 135%;
  color: var(--color-text-black);
  display: flex;
  align-items: center;
  gap: 6px;
}

.select {
  position: relative;

  cursor: pointer;
}

.select svg {
  position: absolute;
  top: 3px;
  margin-left: 5px;
  transition: all 0.3s ease-in-out;
}

.select_open {
  transform: rotate(180deg);
}

.select_dropDown {
  position: absolute;
  top: 45px;
  background-color: white;
  width: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  left: -45px;
  border-radius: 5px;
}

.select_dropDown li {
  width: 100px;
  padding-left: 20%;
  height: 30px;
  padding-bottom: 6px;
}
.select_dropDown li:hover {
  background-color: var(--color-text-black);
  color: white;
}

@media screen and (max-width: 1275px) {
  .left_block {
    width: 100%;
  }
  .nav .left_block_content {
    gap: 20px;
  }
}

@media screen and (max-width: 980px) {
  .left_block {
    width: 80%;
  }
}

@media screen and (max-width: 930px) {
  .left_block {
    width: 75%;
  }
  .nav .left_block_content {
    gap: 28px;
  }
}

@media screen and (max-width: 850px) {
  .nav .left_block_content {
    display: none;
  }
  .left_block {
    width: 100%;
    justify-content: flex-end;
  }
}
